<!-- Envio Email Facturas Emitidas -->

<template>
  <div class="fras_Exp_email">   
    <v-dialog v-model="dialog" content-class="modal" persistent>
      <template v-slot:activator="{on}">       
        <!-- Botón para abrir Finder Documentos -->
        <v-btn 
          v-on='on'
          v-on:click="openComp"
          v-bind="$cfg.btra_cfg.standard"
          title="Envio Facturas Emitidas"
          :disabled="!show">
            <v-icon dark>{{ "mdi-email-send-outline" }}</v-icon>
        </v-btn>       
      </template>

      <div class="contenedor centrado" v-if="schema">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>
          </div> 
          
          <!-- Botoneras -->
          <div class="conflex pl-2 pt-2">
            <v-btn 
              v-bind="$cfg.btra_cfg.standard"
              @click="send_email()">
                <v-icon>{{'mdi-email-send-outline'}}</v-icon>
            </v-btn>
          </div>

          <!-- Controles del Mto -->
          <div class="ctrls conflex contenedor max-width:1240px"> 
            <div class="columna" style="width:550px">
              <div class="conflex">
                <v-text-field
                  style="flex: 0 0 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.fra_doc.value"
                  :label="schema.ctrls.fra_doc.label"
                  readonly>          
                </v-text-field>         

                <!-- <v-text-field
                  style="flex: 0 0 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.fra_fh.value" 
                  :label="schema.ctrls.fra_fh.label"
                  readonly>          
                </v-text-field> -->

                <v-text-field
                  style="flex: 0 0 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.fra_fh.value" 
                  label="Fecha"
                  readonly>          
                </v-text-field>
                
                <v-text-field
                  style="flex: 0 0 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.imp.value"
                  :label="schema.ctrls.imp.label"
                  readonly>          
                </v-text-field>
              </div>
              
              <div class="conflex">
                <v-text-field
                  v-show="accionRow.exp_id>0"
                  style="flex: 0 0 20%"
                  v-bind="$input"
                  v-model="schema.ctrls.control.value"
                  :label="schema.ctrls.control.label"
                  readonly>          
                </v-text-field>
              
              <v-text-field
                  :style="accionRow.exp_id>0 ? 'flex: 0 0 79%' : 'flex: 0 0 99%'"
                  v-bind="$input"
                  v-model="schema.ctrls.name_cta_id.value"
                  :label="schema.ctrls.name_cta_id.label"
                  readonly>          
                </v-text-field>
              </div>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.email_cta_id.value"
                :label="schema.ctrls.email_cta_id.label">          
              </v-text-field>

              <v-select 
                v-show="accionRow.exp_id>0"
                v-bind="$select"
                v-model="schema.ctrls.adjuntos.value"
                :label="schema.ctrls.adjuntos.label"            
                :items="itemsAdjuntos" 
                item-value="id"
                item-text="name"          
                multiple>
              
                <template v-slot:selection="{ index }">              
                  <span  
                    v-if="index=== 0"              
                    class="grey--text caption">                
                      ({{ schema.ctrls.adjuntos.value.length }} documentos adjuntos)
                  </span>
                </template>
                
              </v-select>
              
              <v-textarea
                v-bind="$textarea"
                v-model="schema.ctrls.obs.value"
                :label="schema.ctrls.obs.label"
                rows="3"
                no-resize>                                           
              </v-textarea>

            </div>

          </div>
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import { mixinMtoArray } from "@/mixins/mixinMtoArray.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");  
    
  export default {
    mixins: [mixinMto,mixinMtoArray],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      show: { type: [String, Boolean], default: "" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'fras_Exp_email_M',
        stName:'stMfras_Exp_email',
      
        dialog:false,
        itemsAdjuntos: []
      };
    },

    computed: {
      /* fecha() {
        return (this.accionRow.fra_fh ? this.sql2visual({ format: "fh" }, this.accionRow.fra_fh): null);
      }, */


    },
   

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)",this.accionRow);

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Admon - Impresión / Envio Facturas Emitidas"; 
      },


      // acciones al pulsar el boton de mostrar el componente
      openComp() {
        
        if (this.accionRow.exp_id>0) this.get_itemsExp();
        this.btra_Mto({ accion:0 });

      },


      // 
      async get_itemsExp() {
        let args = { tipo:'fnc', accion: 'documentos', fn_args: { 
          accion:'lista',
          tip:0,
          tip_id: this.accionRow.exp_id
        }};
        console.log('args get_itemsExp: ', args, this.accionRow);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_itemsExp: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.itemsAdjuntos= apiResult.r[0];
      },


      //
      async send_email() {   
        var args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'venta_email', 
          id:this.accionRow.id,
          ema:this.schema.ctrls.email_cta_id.value,
          txt:this.schema.ctrls.obs.value,
          adj:this.schema.ctrls.adjuntos.value }
        }
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Email enviado correctamente!', 'success', 1800);

        // actualizo record de la fra seleccionada
        this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: apiResult.r[0], id: apiResult.r[0].id });
        this.dialog= false;
      },

    }
  };
</script>
